/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
import React from 'react';
import styled from '@emotion/styled';
import Hall from '@assets/images/hall.jpg';
import BooksJPG from '@assets/images/books.jpg';
import Image from '~/components/atoms/Image/Image';
import {
  ImageContainer,
  Container,
  ContainerSmall,
  FlexContainer,
  List,
} from '~/utils/styles/utils';
import { useTranslationContext } from '~/pages/praxis';
import { useTranslationContext as useTranslationContextEn } from '~/pages/en/practice';

const OfficeMain: React.FC = () => {
  const t = useTranslationContext();
  const tEn = useTranslationContextEn();

  return (
    <>
      <Container>
        <ContainerSmall>
          <div
            dangerouslySetInnerHTML={{
              __html: t('OfficeHeading') || tEn('OfficeHeading'),
            }}
          />
          <FlexContainer>
            <ImageContainer>
              <Image src={Hall} alt="Flur" width={350} />
            </ImageContainer>
            <List>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('OfficeContent1') || tEn('OfficeContent1'),
                }}
              />
            </List>
          </FlexContainer>
          <FlexContainer>
            <List>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('OfficeContent2') || tEn('OfficeContent2'),
                }}
              />
            </List>
            <ImageContainer>
              <Books>
                <Image src={BooksJPG} alt="Bücher" width={300} />
              </Books>
            </ImageContainer>
          </FlexContainer>
          <FlexContainer>
            <List />
          </FlexContainer>
        </ContainerSmall>
      </Container>
    </>
  );
};

const Books = styled.div`
  @media (min-width: 800px) {
    margin-left: 2rem;
  }
`;

export default OfficeMain;
